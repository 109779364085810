import { useEffect, useRef } from "react";

function Store() {
  const storeDiv = useRef(null);
  const scriptRef = useRef(null);

  window.localStorage.setItem("show_ecwid_logs", "true");
  (window as any).ecwid_script_defer = true;
  (window as any).ecwid_dynamic_widgets = true;
  (window as any).ec = (window as any).ec || Object();
  (window as any).ec.storefront = (window as any).ec.storefront || Object();
  (window as any).ec.enable_catalog_on_one_page = true;
  (window as any)._xnext_initialization_scripts = [
    {
      widgetType: "ProductBrowser",
      id: "my-store-76907504",
      arg: ["id=productBrowser", "views=grid(20,3)"],
    },
  ];

  var script = document.createElement("script");
  script.charset = "utf-8";
  script.type = "text/javascript";
  script.src =
    "https://app.ecwid.com/script.js?76907504&data_platform=code&data_date=2022-07-06";
  script.defer = true;
  (script as any).ref = scriptRef;

  useEffect(() => {
    if (!scriptRef.current) {
      (storeDiv as any).current.appendChild(script);
    }
  });

  return (
    <>
      <div id="my-store-76907504" ref={storeDiv}></div>
    </>
  );
}

export default Store;
