import React from "react";
import "./App.css";
import Store from "./components/Store";
import Axel from "./assets/axel.jpg";
import Pascal from "./assets/pascal.jpg";

function App() {
  return (
    <>
      <section className="cover">
        <div className="cover-container">
          <div className="mt-10">
            <h1 className="text-white">
              Meer structuur brengen in je benadering en briefing?
            </h1>
            <h2 className="text-white">
              Ontdek nu onze hulpmiddelen voor de prehospitaal medewerker.
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center text-white font-bold text-xl">
            Lees meer
            <i className="fas fa-chevron-down"></i>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <h2>Waarom deze fiches?</h2>
          <p>
            Vanuit de praktijk weten we dat het niet altijd even makkelijk is om
            een prehospitale benadering efficiënt en zo compleet mogelijk te
            laten verlopen. De laatste jaren is er veel tijd en moeite gekropen
            in het opstellen van een{" "}
            <strong>universeel stappenplan (de ABCDE-benadering)</strong> voor
            de benadering van de patiënt. In de praktijk merken we dat iedereen
            dit stappenplan wel min of meer beheerst, maar dat er toch nog veel
            stappen overgeslagen worden die cruciaal zijn in een prehospitaal
            assesment.
          </p>
          <p>
            Daarnaast worden parameters. voorgeschiedenis, medicatiegebruik,
            enz. vaak{" "}
            <strong>genoteerd op de handschoenen van de zorgverlener</strong>.
            Dit is niet altijd even <strong>leesbaar en veilig</strong> (wat bij
            contaminatie met bloed en/of andere lichaamsvochten?).
          </p>
          <p>
            Tot slot horen we vaak veel <strong>frustratie</strong> bij het
            ziekenhuispersoneel omtrent de overdracht. Een{" "}
            <strong>gestructureerde overdracht</strong> volgens een gekend
            systeem (zoals ISBAR of MISTR/ATMIST) zorgt niet enkel voor een{" "}
            <strong>universele structuur</strong>, maar ook voor een{" "}
            <strong>betere nadruk op de relevante parameters</strong>. De
            informatie die wij als prehospitaal medewerker hebben over een
            ongevalsmechanisme, een thuissituatie of een initiële presentatie
            van een ziektebeeld kan{" "}
            <strong>
              cruciaal zijn bij het bepalen van een verder plan van aanpak of
              het stellen van een diagnose
            </strong>
            . Toch merken we dat er slechts sporadisch gestructureerd gebriefd
            wordt.
          </p>
          <p>
            Vanuit deze insteken werden de patiëntenfiches ontwikkeld. Elk
            boekje bevat <strong>50 fiches</strong> die samengehouden worden met
            een lijmrand bovenaan. Het boekje heeft het{" "}
            <strong>A6 formaat</strong> (10,5 x 14,8 cm) en is{" "}
            <strong>slechts 0,5 cm dik</strong>.{" "}
            <strong>
              Het past dus perfect in de zijzak van een interventiebroek
            </strong>
            . De voorkant is opgesteld volgens het{" "}
            <strong>
              federaal benaderingsschema voor de hulpverlener-ambulancier
            </strong>
            . Het helpt om gestructureerd het stappenplan te overlopen en
            abnormaliteiten snel te detecteren en aan te duiden. De achterkant
            is gericht op het <strong>bevragen van de voorgeschiedenis</strong>{" "}
            (volgens SAMPLE) en het <strong>gestructureerd briefen</strong>{" "}
            (volgens ISBAR en MISTR).
          </p>
          <p>
            Bij grote afname's kan er <strong>volumekorting</strong> voorzien
            worden. Ook <strong>personalisatie</strong> van de fiches met het
            logo van uw dienst is mogelijk. Bij interesse kan u contact opnemen
            via{" "}
            <a href="mailto:contact@patientenfiches.be">
              contact@patientenfiches.be
            </a>
            .
          </p>
        </div>
      </section>
      <section className="mb-6 mt-6">
        <div className="container">
          <Store />
        </div>
      </section>
      <section>
        <div className="container">
          <h2>Wie zijn wij?</h2>
          <div className="card-container">
            <div className="card">
              <div
                className="imageSection"
                style={{ backgroundImage: `url(${Axel})` }}
              >
                &nbsp;
              </div>
              <div className="textSection">
                <p className="text-4xl">👋</p>
                <p className="name">Axel Lemmens</p>
                <p>
                  Axel is reeds van kleins af geboeid door de prehospitale
                  wereld. Na het afronden van zijn middelbare studies is hij
                  actief geworden binnen Rode Kruis-Vlaanderen. Daar doorliep
                  hij alle interne opleidingen, waarna hij toch nog zin had in
                  meer en in de opleiding tot hulpverlener-ambulancier rolde. Na
                  deze opleiding is er een heel nieuwe wereld voor hem open
                  gegaan. Hij verdiepte zich verder in pre-hospitale
                  hulpverlening door extra opleidingen en certificaties te
                  volgen zoals PHTLS, AMLS, EPC, ILS en EPILS. Verder volgde hij
                  ook het post-graduaat{" "}
                  <a href="https://viveshealthcareschool.be/nl/opleiding/13263/permanente-vormingen/trauma-en-prehospitaalmanagement">
                    Trauma en prehospitaalmanagement
                  </a>{" "}
                  aan VIVES. Momenteel is hij actief als
                  hulpverlener-ambulancier in het Leuvense en als freelance
                  docent voor{" "}
                  <a href="https://www.vlaamsbrabant.be/pivo/geneeskundige-hulp/index.jsp">
                    PIVO
                  </a>{" "}
                  (de ambulanciersschool van Vlaams-Brabant). Naast zijn passie
                  voor de zorg, heeft hij ook een grote passie voor technologie
                  en innovatie. Hij behaalde in 2019 zijn master in de
                  ingenieurswetenschappen (optie Computerwetenschappen) aan
                  KULeuven. Momenteel is hij dan ook professioneel actief als
                  Technisch Consultant bij <a href="https://www.ae.be/en">AE</a>
                  .
                </p>
              </div>
            </div>
            <div className="card">
              <div
                className="imageSection w-80 h-80 bg-cover rounded-lg border-2 m-6"
                style={{ backgroundImage: `url(${Pascal})` }}
              >
                &nbsp;
              </div>
              <div className="textSection">
                <p className="text-4xl">👋</p>
                <p className="name">Pascal Galand</p>
                <p>
                  Onder invloed van zijn zus, die al lid was van de hulpdienst,
                  voegde Pascal zich op zijn zestiende bij de plaatselijke
                  afdeling van het Rode Kruis in Zoutleeuw waar hij volop
                  meedraaide bij de hulpacties. Na een korte pauze omwille van
                  de studies in Leuven en een verhuis begon het toch terug te
                  kriebelen en trok Pascal naar Rode Kruis-Tienen. Daar doorliep
                  hij alle eerstehulpopleidingen en volgde hij ook de vormingen
                  om als operationele leiding te kunnen worden ingezet. De drang
                  naar meer en beter bleef evenwel present en Pascal besloot dan
                  maar de opleiding tot hulpverlener-ambulancier te volgen.
                  Levenslang leren is zijn grote motto en hij bewijst dit door
                  het volgen van allerhande extra opleidingen: ILS, PHTLS, AMLS,
                  DAC en EPC. Pascal is actief als vrijwillig
                  hulpverlener-ambulancier en korpsinstructeur in het Leuvense.
                  Het professionele leven bracht hem ook naar Leuven, waar hij
                  als leerkracht Frans sinds 2003 lesgeeft in de derde graad van
                  Don Bosco Groenveld. In 2017 kreeg Pascal de kans zijn 2
                  passies te combineren door als deskundige, verantwoordelijk
                  voor de permanente vorming, aan de slag te gaan in het PIVO,
                  de Vlaams-Brabantse school voor DGH.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <h2>Contacteer ons</h2>
          <a href="mailto:contact@patientenfiches.be">
            contact@patientenfiches.be
          </a>
        </div>
      </section>
      <footer className="flex flex-col">
        &copy; patientenfiches.be 2022 | Ondernemingsnummer: BE0757854862
        <a href="/algemene_voorwaarden.html">Algemene voorwaarden</a>
        <a href="/verzending_levering.html">Verzend en leveringsbeleid</a>
      </footer>
    </>
  );
}

export default App;
